@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
.h1 { font-family: 'Heebo'; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 26.4px; text-transform: uppercase; }
.h2 { font-family: 'Heebo'; font-size: 16px; font-style: normal; font-variant: normal; font-weight: 500; line-height: 15.4px; text-transform: uppercase; }  
.h3 { font-family: 'Heebo'; font-size: 18px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 15.4px; text-transform: capitalize; }
.h10 { font-family: 'Heebo'; font-size: 22px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 15.4px; text-transform: capitalize; } 
.p { font-family: Heebo; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 20px; text-transform: uppercase;} 
.blockquote { font-family: Heebo; font-size: 21px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 30px; } 
.pre { font-family: Heebo; font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px; }

html *
{
   font-family: Heebo !important;
}

body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Heebo', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

@font-face {
  font-family: 'Heebo';
  src: local('Heebo'), url(./Heebo/Heebo-VariableFont_wght.ttf) format('truetype');
}

.wrapper {
    background-color: #01060e;
    background-image: 
    radial-gradient(at 44% 33%, rgb(10, 2, 24) 0, transparent 69%), 
    radial-gradient(at 69% 59%, rgb(11, 2, 57) 0, transparent 88%);
} 

.button {
    appearance: auto;
    width: 300px;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: #ffffff;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    cursor: default;
    box-sizing: border-box;
    padding: 1px 6px;
        --angle: 300deg;
    border-image: linear-gradient(
            var(--angle),
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%
        )
        1;
    background-size:cover;
    background-blend-mode: hard-light;
    animation: 3s hue-rotate linear infinite;
    margin: 0 auto;

}

.bg {
    backdrop-filter: blur(12px) saturate(400%);
    -webkit-backdrop-filter: blur(12px) saturate(400%);
    background-color: rgba(18, 2, 46, 0.69);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.065);
    padding: 15px;
}

.bgr {
    background-image: url(./rainbow.png);
    background-position: left top;
    background-size: cover;
    width: 45vw;
    margin: 0 auto;
    text-align: left;
    margin: 0 auto;
    font-size: 20px;
    border-radius: 15px;
}

.no {
    overflow-x: hidden;
}

.video {
    width: 100vw;
    height: 80vh;
    margin: 0 auto;
    text-align: center;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tree {
    margin: 0 auto;
    text-align: center;
    width: 40vw;
    box-shadow: -10px -10px 12px #080216, 2px 2px 12px #1a0846;
}

.text {
    width: 60vw;
    text-align: left;
    margin: 0 auto;
    font-size: 20px;
}

.header {
    width: 60vw;
    margin: 0 auto;
    text-align: center;
    
}

.sac {
    margin: 0 auto;
    text-align: center;
    
}

.rb {
	--angle: 90deg;
	border: 2px solid;
	border-radius: 100px;
	border-image: linear-gradient(
			var(--angle),
			rgba(255, 0, 0, 1) 0%,
			rgba(255, 154, 0, 1) 10%,
			rgba(208, 222, 33, 1) 20%,
			rgba(79, 220, 74, 1) 30%,
			rgba(28, 127, 238, 1) 60%,
			rgba(95, 21, 242, 1) 70%,
			rgba(186, 12, 248, 1) 80%,
			rgba(251, 7, 217, 1) 90%,
			rgba(255, 0, 0, 1) 100%
		)
		1;
    background-size:cover;
    background-blend-mode: hard-light;
	animation: 8s hue-rotate linear infinite;
    margin: 0 auto;
}

.rb2 {
	--angle: 300deg;
	border: 1px solid;
	border-radius: 100px;
	border-image: linear-gradient(
			var(--angle),
			rgba(255, 0, 0, 1) 0%,
			rgba(255, 154, 0, 1) 10%,
			rgba(208, 222, 33, 1) 20%,
			rgba(79, 220, 74, 1) 30%,
			rgba(28, 127, 238, 1) 60%,
			rgba(95, 21, 242, 1) 70%,
			rgba(186, 12, 248, 1) 80%,
			rgba(251, 7, 217, 1) 90%,
			rgba(255, 0, 0, 1) 100%
		)
		1;
    background-size:cover;
    background-blend-mode: hard-light;
	animation: 3s hue-rotate linear infinite;
    margin: 0 auto;
}

@keyframes hue-rotate {
    from {
      -webkit-filter: hue-rotate(0);
      -moz-filter: hue-rotate(0);
      -ms-filter: hue-rotate(0);
      filter: hue-rotate(0);
    }
    to {
      -webkit-filter: hue-rotate(360deg);
      -moz-filter: hue-rotate(360deg);
      -ms-filter: hue-rotate(360deg);
      filter: hue-rotate(360deg);
    }
}

@keyframes rotate {
	to {
		--angle: 233deg;
	}
}

@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}

.rainbow {
  --angle: 30deg;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  width: 70vw;
  min-height: 450px;
  margin: 0 atuo;
  border: double .2em transparent;
  border-top-right-radius: 30px;
  border-top-left-radius: 5px 5px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 5px 5px;
  background-image: linear-gradient(
                      180deg,
                      rgba(255,255,255, .6),
                      rgba(0,0,0, .6)
                    ), 
                    linear-gradient(
                      var(--angle),
                      rgba(255, 0, 0, .4) 0%,
                      rgba(255, 154, 0, .4) 10%,
                      rgba(208, 222, 33, .6) 20%,
                      rgba(79, 220, 74, .6) 30%,
                      rgba(28, 127, 238, .6) 60%,
                      rgba(95, 21, 242, .6) 70%,
                      rgba(186, 12, 248, .6) 80%,
                      rgba(251, 7, 217, .6) 90%,
                      rgba(255, 0, 0, .3) 100%
  );
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: 8s rotate alternate-reverse infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.rainbow::-webkit-scrollbar {
  display: none;
}

.rainbow {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* :root {
    --mx: 50%;
    --my: 50%;
    --s: 1;
    --o: 0;
    --tx: 0px;
    --ty: 0px;
    --rx: 0deg;
    --ry: 0deg;
    --pos: 50% 50%;
    --posx: 50%;
    --posy: 50%;
    --hyp: 0;
}


.card {
    --radius: 4.55% / 3.5%;
    z-index: calc(var(--s) * 100);
    transform: translate3d(0, 0, 0.1px);
    will-change: transform, visibility;
    transform-style: preserve-3d;
}

.card.interacting {
    z-index: calc(var(--s) * 120);
}

.card.active .card__translater,
.card.active .card__rotator {
    touch-action: none;
}

.card__translater,
.card__rotator {
    display: grid;
    perspective: 600px;
    transform-origin: center;
    will-change: transform;
}

.card__translater {
    width: auto;
    position: relative;
    transform: translate3d(var(--tx), var(--ty), 0) scale(var(--s));
}

.card__rotator {
    --glow: #69d1e9;
    transform: rotateY(var(--rx)) rotateX(var(--ry));
    transform-style: preserve-3d;
    box-shadow: 0px 10px 20px -5px black;
    border-radius: var(--radius);
    outline: none;
    transition: box-shadow 0.4s ease, outline 0.2s ease;
}

.card.active .card__rotator {
    box-shadow: 0 0 10px 0px var(--glow), 0 0 10px 0px var(--glow),
        0 0 30px 0px var(--glow);
}

.card__rotator:focus {
    box-shadow: 0 0 10px 0px var(--glow), 0 0 10px 0px var(--glow),
        0 0 30px 0px var(--glow);
}

.card.active .card__rotator:focus {
    box-shadow: 0px 10px 30px 3px black;
}

.card__rotator :global(*) {
    width: 100%;
    display: grid;
    grid-area: 1/1;
    border-radius: var(--radius);
    image-rendering: optimizeQuality;
    transform-style: preserve-3d;
}

.card__back {
    transform: rotateY(180deg);
    backface-visibility: visible;
}

.card__front,
.card__front * {
    backface-visibility: hidden;
}

.card__front {
    opacity: 1;
    transition: opacity 0.2s ease-out;
}

.loading .card__front {
    opacity: 0;
}

.loading .card__back {
    transform: rotateY(0deg);
}

.card__shine {
    --grain: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCI+CjxmaWx0ZXIgaWQ9Im4iPgo8ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjciIG51bU9jdGF2ZXM9IjEwIiBzdGl0Y2hUaWxlcz0ic3RpdGNoIj48L2ZlVHVyYnVsZW5jZT4KPC9maWx0ZXI+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWxsPSIjMDAwIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9IjAuMyI+PC9yZWN0Pgo8L3N2Zz4=");

    --space: 5%;
    --angle: 133deg;
    --imgsize: 500px;

    --red: #f80e7b;
    --yel: #eedf10;
    --gre: #21e985;
    --blu: #0dbde9;
    --vio: #c929f1;
}


.card_shine {

    --space: 5%;
    --angle: -22deg;
    --imgsize: 200% 400%;
  
    clip-path: inset(2.8% 4% round 2.55% / 1.5%);
  
    background-image:
      repeating-linear-gradient( var(--angle),  
        rgba(174, 102, 202, 0.75) calc(var(--space)*1),
        rgba(228, 77, 72, 0.75) calc(var(--space)*2), 
        rgba(216, 197, 55, 0.75) calc(var(--space)*3),  
        rgba(124, 201, 62, 0.75) calc(var(--space)*4),  
        rgba(80, 177, 170, 0.75) calc(var(--space)*5),  
        rgba(136, 160, 255, 0.75) calc(var(--space)*6), 
        rgba(176, 105, 204, 0.75) calc(var(--space)*7)
      );
      
      background-blend-mode: color-dodge;
      background-size: var(--imgsize), 300%, 200%;
      background-position: 0% calc(var(--posy) * 1), var(--posx) var(--posy);
      
      filter: brightness(calc((var(--hyp)*0.3) + 0.6)) contrast(2.3) saturate(1.1);
  
  }
  
  .card__shine:after {
    
    content: "";
  
    background-image: 
      radial-gradient( 
        farthest-corner ellipse 
        at calc( ((var(--mx)) * 0.5) + 25% ) calc( ((var(--my)) * 0.5) + 25% ), 
        rgb(255, 255, 255) 5%, 
        rgba(55, 0, 55, .6) 25%, 
        rgb(55, 55, 55) 90% 
      );
  
    background-position: center;
    background-size: 200% 200%;
  
    filter: brightness(calc((var(--hyp)*0.2) + 0.4)) contrast(.85) saturate(1.1);
    mix-blend-mode: hard-light;
  
  } */


