body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rainbow-box {
	font: small-caps bold 15px/30px system-ui, serif;
	border-radius: 5px;
	background: linear-gradient(
		160deg,
		#560a9d 10%,
		#b70cff 20%,
		#2e06fe 40%,
		#fe0001 60%,
		#fe6008 70%,
		#ffff01 80%,
		#2fe401 100%,
		#a20328 100%
	);
}

.rainbow-box:hover {
	box-shadow: -4px 4px 12px rgb(35 177 240 / 35%), 4px 4px 12px rgb(186 27 241 / 50%);
}
